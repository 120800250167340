import { currentVersion } from '../app/appVersion';
// export const environment = {
//   production: false,
//   baseUrl: window.location.origin + '/api/radial',
//   rucUrl: window.location.origin + '/api/ruc',
//   pingUrl: window.location.origin + '/api/ping/userinfo',
//   generateReportUrl:
//     window.location.origin +
//     '/generatereport/cpyn5fak3mqn7avitwbrcpxsqe0diyob.lambda-url.eu-west-1.on.aws',
//   qlikViewGraphUrl:
//     window.location.origin + '/api/radial/clientbaselinereports',
//   cbrGraphUrl: window.location.origin + '/api/radial/clientbaselinereports',
//   clientConfigUrl: window.location.origin + '/api/radial/clientconfig/',
//   userConfigUrl: window.location.origin + '/api/radial/userconfig/',
//   logout: 'https://login.bpost.be/idp/startSLO.ping',
//   VERSION: currentVersion,
//   googleAnalyticsMeasurementId: 'G-4G1VE1QGMY',
//   reportUrl: 'https://datawarehouse-report.s3.eu-west-1.amazonaws.com/',
//   rootUrl: window.location.origin,
//   DEPLOYED_BRANCH_NAME: '',
//   DEPLOYED_BRANCH_URL: '',
// };
export const environment = {
  googleAnalyticsMeasurementId: 'G-4G1VE1QGMY',
  production: false,
  baseUrl: 'https://radial-st1.bpost.cloud/api/radial',
  rucUrl: 'https://radial-st1.bpost.cloud/api/ruc',
  pingUrl: 'https://radial-st1.bpost.cloud/api/ping/userinfo',
  qlikViewGraphUrl:
    'https://radial-st1.bpost.cloud/api/radial/clientbaselinereports',
  generateReportUrl:
    'https://radial-st1.bpost.cloud/generatereport/cpyn5fak3mqn7avitwbrcpxsqe0diyob.lambda-url.eu-west-1.on.aws',
  cbrGraphUrl:
    'https://radial-st1.bpost.cloud/api/radial/clientbaselinereports',
  clientConfigUrl: 'https://radial-st1.bpost.cloud/api/radial/clientconfig/',
  userConfigUrl: 'https://radial-st1.bpost.cloud/api/radial/userconfig/',
  reportUrl: 'https://datawarehouse-report.s3.eu-west-1.amazonaws.com/',
  logout: 'https://login.bpost.be/idp/startSLO.ping',
  rootUrl: 'https://radial-st1.bpost.cloud',
  VERSION: currentVersion,
  DEPLOYED_BRANCH_NAME: '',
  DEPLOYED_BRANCH_URL: '',
};
